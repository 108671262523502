import Image from 'next/image'
import clsx from 'clsx'
import styles from './ProductTab.module.css'
import CognisantButton from '@/common/components/button/CognisantButton'
import SubscribeBg from '@/assets/img/buttonBg/subscribe_pink_169x45.svg'

const ProductTab = props => {
  const tabData = props.data
  return (
    <div className={styles.product_tab_main}>
      <h1 className={styles.what_cognisant}>What is Firesight?</h1>
      <div>
        <div className={styles.custom_section}>
          <div className="flex flex-col md:flex-row lg:flex-col justify-center items-center">
            {tabData.icon ? (
              <Image
                src={tabData.icon}
                alt="tab icon"
                className="mr-0 md:mr-3 lg:m-auto w-8 lg:w-12 h-8 lg:h-12"
              />
            ) : (
              ''
            )}
            <h2 className={styles.title}>{tabData.title}</h2>
          </div>
          <p className={styles.description}>{tabData.description}</p>
        </div>
        <div className={styles.banner_img}>
          <Image
            src={tabData.banner}
            alt="tab banner"
            width={896}
            height={896}
            priority={true}
            className={clsx(
              tabData.title === 'Overview' ? 'm-auto' : 'w-full m-auto'
            )}
          />
        </div>
      </div>
      <div>
        {tabData &&
          tabData.subsections.map((item, index) => {
            return (
              <div key={index}>
                <div className={styles.custom_section}>
                  <h2
                    className={clsx(
                      tabData.title === 'Overview'
                        ? styles.overview_subtitle
                        : styles.subtitle
                    )}
                  >
                    {item.title}
                  </h2>
                  <p className={styles.subdescription}>{item.description}</p>
                </div>
                {item.mobileBanner ? (
                  <div className={styles.mobile_banner_img}>
                    <Image
                      src={item.mobileBanner}
                      alt="mobile mockup"
                      width={896}
                      height={896}
                      priority={true}
                      className={clsx(index === 1 ? 'm-auto' : 'w-full m-auto')}
                    />
                  </div>
                ) : (
                  ''
                )}
                {item.banner ? (
                  <div className={styles.banner_img}>
                    <Image
                      src={item.banner}
                      alt="mobile akfgh"
                      width={896}
                      height={896}
                      priority={true}
                      className={clsx(
                        index === 1 ? 'm-auto' : 'w-full m-auto hidden md:block'
                      )}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            )
          })}
      </div>
      <div className="w-full flex flex-col items-center mb-[50px] mt-[30px] md:mt-[72px] lg:mt-0">
        <div className="flex flex-col md:flex-row md:gap-3 items-center">
          <h2 className="text-[28px] md:text-[32px] lg:text-[42px] font-bold">
            WORK LESS.
          </h2>
          <h2 className="text-[28px] md:text-[32px] lg:text-[42px] font-bold text-customRed">
            ACHIEVE MORE.
          </h2>
        </div>
        <p className="text-[12px] md:text-[18px] lg:text-[19px] mt-[20px] mb-[24px] md:my-6 lg:my-[50px]">
          Claim your <b>free monthly credits</b>
        </p>
        <CognisantButton
          bgImage={SubscribeBg}
          bgClass="w-[134px] md:w-[111px] lg:w-[169px] h-7 md:h-[34px] lg:h-[45px]"
        >
          <span className="text-xs/[150%] md:text-sm/[125px] lg:text-lg/[125px] font-bold">
            Register Now
          </span>
        </CognisantButton>
      </div>
    </div>
  )
}

export default ProductTab
